import { IonCard, IonCardHeader, IonCardTitle, IonCol, IonRow } from '@ionic/react';
import Img, { ImgProps } from 'components/Img';
import { useTranslation } from 'react-i18next';
import { TopicCategory } from 'types';

type CardProps = {
    item: TopicCategory;
    routerLink?: string;
    imgProps?: ImgProps;
};

function TopicCard({ item, routerLink, imgProps }: CardProps) {
    const { t } = useTranslation();

    return (
        <IonCard
            className="m-0 category__card group/hovercard max-w-[330px] mx-auto"
            routerLink={routerLink}
        >
            <Img
                src={item.pictureUrl}
                hasCardHoverEffect
                className="h-[106px]"
                {...(imgProps || {})}
                imgClassName="min-h-[106px] max-h-[125px] !w-full !object-cover object-top"
            />
            <IonCardHeader>
                <IonCardTitle className="typo-b1 font-semibold">{t(item.name)}</IonCardTitle>
            </IonCardHeader>
        </IonCard>
    );
}

export default TopicCard;

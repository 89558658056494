import { useTranslation } from 'react-i18next';
import FocusSwiper from 'FocusSwiper';
import MyWidgets from 'components/Widgets';
import PageLayout from 'components/PageLayout';
import UserAccountCard from 'components/UserAccountCard';
import Title from 'components/Title';
import { useContext, useMemo, useState } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { IonCol, IonGrid, IonRefresher, IonRefresherContent, IonRow, RefresherEventDetail } from '@ionic/react';
import useResponsive from 'features/responsive/responsive';
import TopicCategoriesList from 'features/topics/components/TopicCategoriesList';
import BestMomentsSwiper from 'features/bestMoments/components/Eldo4RealSwiper';
import AdComponent from 'components/Ads/AdComponent';
import useAds from 'hooks/useAds';
import { Preferences } from '@capacitor/preferences';
import { PicturesReport } from 'types';
import LoadingIndicator from 'components/LoadingIndicator';
import InPage from 'components/Ads/Inpage';
import { getThumbnailForHorizontalArticleList } from 'components/core/ArticlePageComponent';
import { useQueryClient } from '@tanstack/react-query';
import BannerForApp from 'components/BannerForApp';
import { isPlatform } from '@ionic/core';
import { prepareUrlTitle } from 'lib/utils';
import CountDown from 'components/Countddown';
import useHomePage from '../features/homepage/hooks/useHomePage';
import LastSongs from '../features/radio/component/LastSongs';
import RadioCard from '../components/Cards/RadioCard';
import { PlayerContextType, PlayerContext } from '../features/radio/PlayerContext';
import { Presenter } from '../features/radio/types';
import HorizontalMediumCard from '../components/HorizontalMediumCard';
import Section from '../components/core/Section';
import CardVertical from '../components/core/CardVertical';
import CarouselWithAds from '../components/Carousel/CarouselWithAds';
import CardListingChunksWithAds from '../components/CardListingChunksWithAds';
import PageNoData from './PageNoData';

function HomePage() {
    const { playground, playgroundId } = useParams<{ playgroundId: string; playground: string }>();
    if (playground === 'playground') {
        Preferences.set({ key: 'playground', value: playgroundId ?? '' });
    }
    const queryClient = useQueryClient();
    const { ads } = useAds({ page: 'homepage' });
    const [adsWidgets, setAdsWidgets] = useState({ ad1: true, ad2: true, ad3: true, ad4: true });
    const { t } = useTranslation();
    const { homepageData, isLoading, refetch } = useHomePage();
    const { IsMobile } = useResponsive();
    const { playerState, playerDispatch } = useContext(PlayerContext) as PlayerContextType;
    const presenters: string = useMemo(
        () => homepageData?.radio.presenters?.map((presenter: Presenter) => presenter.name).join(' - ') || '',
        [homepageData?.radio],
    );

    const [clickCounts, setClickCounts] = useState({
        div1: 0,
        div2: 0,
        div3: 0,
    });

    const handleClick = (div: 'div1' | 'div2' | 'div3') => {
        setClickCounts((prevClickCounts) => ({
            ...prevClickCounts,
            [div]: prevClickCounts[div] + 1,
        }));
    };

    const checkSequence = async () => {
        const { div1, div2, div3 } = clickCounts;

        if (div1 === 3 && div2 === 1 && div3 === 2) {
            alert('Hidden feature unlocked!');
            // Reset the click counts
            setClickCounts({
                div1: 0,
                div2: 0,
                div3: 0,
            });

            const input = prompt('Enter a value for the playground:');
            if (input !== null) {
                // Save the user input in local storage
                await Preferences.set({ key: 'playground', value: input });
            }
        } else if (div1 > 3 || div2 > 1 || div3 > 2) {
            // Reset if any div is clicked too many times
            setClickCounts({
                div1: 0,
                div2: 0,
                div3: 0,
            });
        }
    };

    if (!homepageData && !isLoading) {
        return <PageNoData />;
    }

    const HandleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        queryClient.clear();
        refetch().then(() => {
            event.detail.complete();
        });
    };

    return (
        <PageLayout
            cssClass="eldo-page tall-page"
            hasLogo
        >
            <IonRefresher
                slot="fixed"
                className=""
                onIonRefresh={(e: CustomEvent) => HandleRefresh(e)}
            >
                <IonRefresherContent refreshingSpinner="crescent" />
            </IonRefresher>
            <IonGrid fixed>
                {!isLoading && homepageData ? (
                    <IonRow>
                        <IonCol>
                            {ads && <AdComponent ad={ads.data[0]} />}
                            <CountDown
                                src={homepageData?.banner?.image}
                                title={homepageData?.banner?.title}
                                subtitle={homepageData?.banner?.subtitle}
                            />

                            {IsMobile && (
                                <Section>
                                    <UserAccountCard />
                                </Section>
                            )}
                            <Section
                                title={t('focuses')}
                                onClick={() => {
                                    handleClick('div1');
                                    checkSequence();
                                }}
                            >
                                <FocusSwiper focuses={homepageData.focuses} />
                            </Section>
                            <Section>
                                <div className="flex max-md:flex-col gap-standardMobile md:gap-standard">
                                    <div>
                                        <div>
                                            <Title
                                                title={t('on_air')}
                                                onClick={() => {
                                                    handleClick('div2');
                                                    checkSequence();
                                                }}
                                                className="mb-blockInner"
                                            />
                                            <RadioCard
                                                className="flex-1 mt-block-innerMobile md:mt-block-inner"
                                                src={homepageData.radio.presenters[0]?.thumbnailUrl}
                                                title={homepageData.radio.show?.name || ''}
                                                liveStart={moment(homepageData.radio.show?.startDate).format('HH:mm')}
                                                liveEnd={moment(homepageData.radio.show?.endDate).format('HH:mm')}
                                                showRunner={presenters}
                                                isPlaying={playerState.isPlaying && playerState.type === 'RADIO'}
                                                onPlayerClick={() => {
                                                    playerDispatch({
                                                        type: 'TOGGLE_RADIO',
                                                    });
                                                }}
                                                displayWatchBtn
                                                isLive
                                                bigSponsor={false}
                                                sponsor={homepageData.radio.show?.sponsor?.image}
                                            />
                                        </div>
                                        <div
                                            className="mt-block-innerMobile md:mt-block-inner"
                                            onClick={() => {
                                                handleClick('div3');
                                                checkSequence();
                                            }}
                                        >
                                            <LastSongs
                                                songs={homepageData.lastSongs}
                                                className="mt-block"
                                            />
                                        </div>
                                    </div>
                                    {ads && (
                                        <div className="min-w-[300px]">
                                            <AdComponent
                                                ad={ads.data[1]}
                                                hasSpacings={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Section>
                            <Section
                                title={t('whats_new')}
                                path="/aktuell/news"
                            >
                                <CardListingChunksWithAds
                                    removeMinHeight
                                    adNativeClassName="!h-[175px] overflow-hidden"
                                    data={homepageData.news.articles}
                                >
                                    {(item) => (
                                        <div>
                                            <HorizontalMediumCard
                                                truncated={3}
                                                title={item.title}
                                                date={item.date}
                                                tags={(item.tags || []).filter((tag) => tag.publicTag !== 'highlights')}
                                                thumbnailUrl={getThumbnailForHorizontalArticleList(item) || ''}
                                                linkTo={`${
                                                    item.category_path !== ''
                                                        ? (item.category_path as unknown as string)
                                                        : '/aktuell/news'
                                                }/a/${item.id as unknown as string}/${prepareUrlTitle(item?.title)}`}
                                                linkForPill="aktuell/news/"
                                                className="max-h-[175px]"
                                                imageCover={item.header?.listing_news ? 'object-fit' : 'object-cover'}
                                            />
                                        </div>
                                    )}
                                </CardListingChunksWithAds>
                            </Section>
                            {ads && (
                                <AdComponent
                                    ad={ads.data[2]}
                                    preloadMovieJobAd
                                />
                            )}

                            <Section
                                title={t('eldo4real')}
                                path="/podcast-replay/category/eldo4real"
                            >
                                <BestMomentsSwiper />
                            </Section>

                            {!isPlatform('cordova') && <BannerForApp />}

                            <MyWidgets title={t('main.my_widgets')} />

                            {(adsWidgets.ad1 || adsWidgets.ad2 || adsWidgets.ad3 || adsWidgets.ad4) && (
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-standardMobile md:gap-standard md:mb-block mb-blockMobile">
                                    {ads && adsWidgets.ad1 && (
                                        <AdComponent
                                            ad={ads.data[3]}
                                            hasSpacings={false}
                                            className="!max-h-[125px] overflow-hidden"
                                            onNoContent={() => setAdsWidgets({ ...adsWidgets, ad1: false })}
                                        />
                                    )}
                                    {ads && adsWidgets.ad2 && (
                                        <AdComponent
                                            ad={ads.data[4]}
                                            hasSpacings={false}
                                            className="!max-h-[125px] overflow-hidden"
                                            onNoContent={() => setAdsWidgets({ ...adsWidgets, ad2: false })}
                                        />
                                    )}
                                    {ads && adsWidgets.ad3 && (
                                        <AdComponent
                                            ad={ads.data[5]}
                                            hasSpacings={false}
                                            className="!max-h-[125px] overflow-hidden"
                                            onNoContent={() => setAdsWidgets({ ...adsWidgets, ad3: false })}
                                        />
                                    )}
                                    {ads && adsWidgets.ad4 && (
                                        <AdComponent
                                            ad={ads.data[6]}
                                            hasSpacings={false}
                                            className="!max-h-[125px] overflow-hidden"
                                            onNoContent={() => setAdsWidgets({ ...adsWidgets, ad4: false })}
                                        />
                                    )}
                                </div>
                            )}

                            {ads && (
                                <InPage
                                    adSrc={
                                        ((Array.isArray(ads.data) ? ads?.data : null) || [])?.filter(
                                            (item) => item.container === 'inpage',
                                        )[0]?.url ?? ''
                                    }
                                    nameOfElementId="homePageInpage"
                                />
                            )}

                            <Section
                                title={t('pictures_report')}
                                path="/events/fotoen"
                                waveContainerProps={{
                                    autoMargin: true,
                                    fixed: 'block',
                                    reverse: true,
                                    position: 'bottom-left',
                                    className: 'md:my-block my-blockMobile',
                                    component: 'section',
                                }}
                            >
                                <CarouselWithAds<PicturesReport> data={homepageData.picturesReport.list}>
                                    {(report) => (
                                        <Link
                                            to={`/events/fotoen/${report.id as unknown as string}/${prepareUrlTitle(
                                                report?.title,
                                            )}`}
                                            className="block h-full w-full relative"
                                        >
                                            <div className="md:duration-300 md:hover:scale-105 relative h-[470px]">
                                                <CardVertical
                                                    thumbnailUrl={report.thumbnailUrl}
                                                    tags={report.tags}
                                                    title={report.title}
                                                    className="h-full w-full"
                                                    imgClassName="!h-[280px] !w-full !object-cover object-top"
                                                    hasWavePattern={false}
                                                    hasCardHoverEffect={false}
                                                    titleTruncated={3}
                                                />
                                                <div
                                                    className={`before:m-[10px] ${
                                                        IsMobile
                                                            ? 'picture-report-cut-corner-mobile'
                                                            : 'picture-report-cut-corner'
                                                    }`}
                                                />
                                            </div>
                                        </Link>
                                    )}
                                </CarouselWithAds>
                            </Section>

                            {ads && <AdComponent ad={ads.data[7]} />}
                            <TopicCategoriesList categories={homepageData.topics} />
                            {/* <Section
                                title={t('menu.topics')}
                                path="/radio/rubriken"
                                waveContainerProps={{
                                    autoMargin: true,
                                    fixed: 'block',
                                    reverse: true,
                                    position: 'bottom-left',
                                    className: 'md:my-block my-blockMobile',
                                    component: 'section',
                                }}
                            >
                                <CarouselWithAds<TopicCategory>
                                    data={homepageData.topics}
                                    slidesPerView={IsMobile ? 1.45 : undefined}
                                >
                                    {(topic) => (
                                        <Link
                                            to={getRouterLinkForTopics(topic)}
                                            className="block h-full w-full relative"
                                        >
                                            <div className="md:duration-300 md:hover:scale-105 relative h-[470px]">
                                                <CardVertical
                                                    thumbnailUrl={topic.pictureUrl}
                                                    imgClassName="!h-[300px] object-cover !mb-[15px]"
                                                    title={topic.name}
                                                    hasWavePattern={false}
                                                    hasCardHoverEffect={false}
                                                />
                                            </div>
                                        </Link>
                                    )}
                                </CarouselWithAds>
                            </Section> */}
                        </IonCol>
                    </IonRow>
                ) : (
                    <LoadingIndicator />
                )}
            </IonGrid>
        </PageLayout>
    );
}

export default HomePage;

import { useEffect } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

function InstagramEmbedComponent({ html }: { html: string }) {
    // Load Instagram embed.js script manually
    useEffect(() => {
        const scriptId = 'instagram-embed-script';

        // Check if the script is already added
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = 'https://www.instagram.com/embed.js';
            script.async = true;
            script.defer = true;
            script.onload = () => {
                // @ts-ignore
                if (window.instgrm) {
                    // @ts-ignore
                    window.instgrm.Embeds.process(); // Ensure Instagram embeds are initialized
                }
            };
            document.body.appendChild(script);
        } 
        if (document.getElementById(scriptId)) {
            // If script already exists, process embeds again
            // @ts-ignore
            if (window.instgrm) {
                // @ts-ignore
                window.instgrm.Embeds.process();
            }
        }
    }, []);

    // Function to extract Instagram URL from provided HTML
    const extractInstagramURL = (htmlString: string) => {
        const regex = /https:\/\/www.instagram.com\/(?:p|reel)\/[^/]+/g;
        const match = htmlString.match(regex);
        return match ? match[0] : null;
    };

    // Extract the Instagram URL
    const instagramURL = extractInstagramURL(html);

    if (!instagramURL) {
        return null;
    }

    return (
        <div className="mx-auto max-w-md">
            <div className="[&_.instagram-media]:!max-w-[275px] [&_.instagram-media]:!mx-auto">
                <InstagramEmbed url={instagramURL} debug />
            </div>
        </div>
    );
}

export default InstagramEmbedComponent;

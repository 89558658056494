import { TikTokEmbed } from 'react-social-media-embed';

export default function TiktokEmbedComponent({ html }: { html: string }) {
    // Define a function to extract the Instagram URL from the provided HTML string
    const extractTiktokURL = (htmlString: string) => {
        const regex = /https:\/\/www\.tiktok\.com\/@[^/]+\/video\/\d+/g;
        const match = htmlString.match(regex);
        return match ? match[0] : null;
    };

    // Extract the Tiktok URL from the provided HTML string
    const tiktokURL = extractTiktokURL(html);

    if (!tiktokURL) {
        return null;
    }

    return (
        <div className="flex justify-center">
            <TikTokEmbed url={tiktokURL} debug />
        </div>
    );
}

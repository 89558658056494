import { IonApp, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, isPlatform } from '@ionic/react';
import useFirebaseAnalytics from 'hooks/useFirebaseAnalytics';
import EditAccountInfoPage from 'pages/EditAccountInfoPage';
import EditPasswordPage from 'pages/EditPasswordPage';
import EditAccountEmailPage from 'pages/EditAccountEmailPage';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import MyAccountPage from 'pages/MyAccountPage';
import PicturesReports from 'pages/PicturesReports';
import PicturesReport from 'pages/PicturesReport';
import TrafficInfo from 'pages/TrafficInfo';
import GamePage from 'pages/GamePage';
import GameFormPage from 'pages/GameFormPage';
import TicketingPage from 'pages/TicketingPage';
import TicketingSearchPage from 'pages/TicketingSearchPage';
import PodcastOrReplayCategorized from 'pages/PodcastOrReplayCategorized';
import EventsPage from 'pages/EventsPage';
import EventsArchivePage from 'pages/EventsArchivePage';
import EventArticle from 'pages/EventArticle';
import CategoryPage from 'pages/CategoryPage';
import ShowsList from 'pages/ShowsList';
import PodcastReplay from 'pages/PodcastReplay';
import PodcastReplayOrEldo4Real from 'pages/PodcastReplayOrEldo4Real';
import ReactGA from 'react-ga4';
import { getPlatformForGemius } from 'features/advertising/generateAdPayload';
import sendPermutivePayload from 'features/advertising/generatePermutivePayload';
import TicketBourseForm from 'pages/TicketBourseForm';
import CategoryListPage from 'pages/CategoryListPage';
import ActionsPage from 'pages/ActionsPage';
import ActionsArticlePage from 'pages/ActionsArticlePage';
import AppUrlListener from 'DeepLinkRedirect';
import { setPortraitModeOnPhone } from 'lib/utils';
import { useCookies } from 'react-cookie';
import LetzAiApprovedPicture from 'components/letzAi/LetzAiApprovedPicture';
import AnalyticsCategory from 'lib/AnalyticsCategory';
import { Device } from '@capacitor/device';
import RadioPage from './pages/RadioPage';
import MenuPage from './pages/MenuPage';
import LoginPage from './pages/LoginPage';
import MeteoPage from './pages/MeteoPage';
import PlayerWidget from './features/radio/component/player/PlayerWidget';
import AppIcon from './components/Icons/AppIcon';
import { HOME, MENU, NEWS, RADIO } from './constants';
import TopicsCategoriesPage from './pages/TopicsCategoriesPage';
import ShowsPage from './pages/ShowsPage';
import { AuthContext, AuthContextProps } from './features/auth/AuthContext';
import SignupPage from './pages/SignupPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import NewsPage from './pages/NewsPage';
import NewsArticle from './pages/NewsArticle';
import TeamPage from './pages/TeamPage';
import HomePage from './pages/HomePage';
import ChartBreakPage from './pages/ChartBreaker';
import ChartBreakerTopic from './pages/ChartbreakerTopic';
import LegalMentions from './pages/LegalMentions';
import EventSearchPage from './pages/EventSearchPage';
import LiveStreamPage from './pages/LiveStreamPage';
import FrequenciesPage from './pages/FrequenciesPage';
import ShopPage from './pages/ShopPage';
import OnboardingPage from './pages/OnboardingPage';
import CinemaPage from './pages/CinemaPage';
import BabyOfTheMonthPage from './pages/BabyOfTheMonthPage';
import MacdoBurgerAlarmPage from './pages/MacdoBurgerAlarmPage';
import CinemaMoviePage from './pages/CinemaMoviePage';
import SplashScreenAd from './components/Ads/SplashScreenAd';

function AuthRoute({ path, children, render, exact }: RouteProps) {
    const { user } = useContext<AuthContextProps | null>(AuthContext) as AuthContextProps;

    if (!user) {
        return <Redirect to="/login" />;
    }

    return (
        <Route
            path={path}
            exact={exact}
            render={render}
        >
            {children}
        </Route>
    );
}

function shouldSendPermutivePageviewTrack() {
    const currentURL = window.location.pathname;

    const listOfPagesToNotSendPermutivePageviewTrack = [
        '/aktuell/news/a/',
        '/kino/articles/',
        '/events/fotoen/',
        '/podcast-replay/type/podcast/',
        '/podcast-replay/type/replay/',
        '/aktiounen/',
        '/events/agenda/',
        '/movies/',
    ];

    return listOfPagesToNotSendPermutivePageviewTrack.some((route) => {
        const regex = new RegExp(`^${route}[^/]+$`);
        return regex.test(currentURL);
    });
}

export function shouldSendArticleId() {
    const currentURL = window.location.pathname;

    const listOfPagesToNotSendPermutivePageviewTrack = [
        '/aktuell/news/a/',
        '/kino/articles/',
        '/events/fotoen/',
        '/podcast-replay/type/podcast/',
        '/podcast-replay/type/replay/',
        '/events/agenda/',
        '/kino/kinoprogramm/movies/',
    ];

    //  Add the NewsArticle route pattern
    const newsArticleRoutePattern = /^\/radio\/[^/]+\/a\/[^/]+$/;

    //  Check if the current URL matches any of the excluded routes or the NewsArticle route
    return (
        listOfPagesToNotSendPermutivePageviewTrack.some((route) => {
            const regex = new RegExp(`^${route}[^/]+$`);
            return regex.test(currentURL);
        }) || newsArticleRoutePattern.test(currentURL)
    );
}

function UrlChangeTracker() {
    const location = useLocation();
    useFirebaseAnalytics();
    // const { ads } = useAds({ page: 'track-pixel', reloadOnChangeString: `${location.pathname}${location.hash}` });

    const onLocationOrHashChange = () => {
        const currentPath = window.location.pathname;
        //  This prevents the double call to be made rehgarding Google Analytics but does send hashtagchanges
        if (!isPlatform('cordova') && window.location.href.includes('#')) {
            ReactGA.send({ hitType: 'pageview', page: currentPath });
        }

        const metriwebIdentifier = process.env.REACT_APP_METRIWEB_IDENTIFIER || '';
        // addView(`pageview-${currentPath}`);

        getPlatformForGemius().then(async (device) => {
            const extraParameters = [metriwebIdentifier, 'lan=LU', `key=${device}`, `subs=${device}${currentPath}`];

            if (isPlatform('cordova')) {
                try {
                    const deviceInfo = await Device.getId();
                    if (deviceInfo && deviceInfo.identifier) {
                        extraParameters.push(`deviceId=${deviceInfo.identifier}`);
                    }
                } catch (error) {
                    console.error('Error getting device ID:', error);
                }
                const permutiveId = localStorage.getItem('permutive-id');
                if (permutiveId) {
                    extraParameters.push(`permutiveId=${permutiveId}`);
                }
            }

            // @ts-ignore
            gemius_hit.apply(metriwebIdentifier, extraParameters);
        });

        if (!shouldSendPermutivePageviewTrack()) {
            sendPermutivePayload();
        }
    };

    const [cookies, setCookie] = useCookies(['__gfp_64b', 'didomi_token']);
    useEffect(() => {
        // eslint-disable-next-line
        if ((cookies.__gfp_64b || localStorage.getItem('didomi')) && isPlatform('cordova')) {
            const cacheBuster = Date.now();
            const mwScript = document.createElement('script');
            mwScript.src = `${process.env.REACT_APP_API_URL ?? 'https://api-mobile.eldo.lu'}/js/mw.js?v=${cacheBuster}`;
            mwScript.async = true;
            document.head.appendChild(mwScript);
        } else {
            const cacheBuster = Date.now();
            const gemiusScript = document.createElement('script');
            gemiusScript.src = `https://gabe.hit.gemius.pl/xgemius.js?v=${cacheBuster}`;
            gemiusScript.async = true;
            document.head.appendChild(gemiusScript);
        }
    }, []);

    useEffect(() => {
        document.title = `Eldo - ${location.pathname}`;
        onLocationOrHashChange();
        setPortraitModeOnPhone();
    }, [location]);

    // return <div>{ads && <AdComponent ad={ads.data[0]} />}</div>;
    return <div />;
}

export default function AppRoutes() {
    const [isSplashAdHidden, setSplashAdHidden] = useState<boolean>(false);

    const router = useRef<IonReactRouter>(null);

    const hideSplashAd = () => {
        setSplashAdHidden(true);
    };

    return (
        <IonApp>
            <PlayerWidget />
            <IonReactRouter ref={router}>
                <AnalyticsCategory />

                <AppUrlListener />
                <UrlChangeTracker />
                <SplashScreenAd onHide={hideSplashAd} />
                {isSplashAdHidden ? (
                    <IonTabs>
                        <IonRouterOutlet>
                            <Switch>
                                <Route
                                    path="/menu"
                                    exact
                                >
                                    <MenuPage />
                                </Route>
                                <Route path="/signup/:firstName?/:lastName?">
                                    <SignupPage />
                                </Route>
                                <Route
                                    path="/account"
                                    exact
                                >
                                    <MyAccountPage />
                                </Route>
                                <Route
                                    path="/account-info"
                                    exact
                                >
                                    <EditAccountInfoPage />
                                </Route>
                                <Route
                                    path="/account-password"
                                    exact
                                >
                                    <EditPasswordPage />
                                </Route>
                                <Route
                                    path="/account-email"
                                    exact
                                >
                                    <EditAccountEmailPage />
                                </Route>
                                <Route
                                    path="/login"
                                    exact
                                >
                                    <LoginPage />
                                </Route>
                                <Route
                                    path="/reset-password"
                                    exact
                                >
                                    <ResetPasswordPage />
                                </Route>
                                <Route
                                    path="/aktuell/meteo"
                                    exact
                                >
                                    <MeteoPage />
                                </Route>
                                <Route
                                    path="/aktuell/radio"
                                    exact
                                >
                                    <RadioPage />
                                </Route>
                                <Route
                                    path="/aktuell/shop"
                                    exact
                                >
                                    <ShopPage />
                                </Route>
                                <Route
                                    path="/kino"
                                    exact
                                >
                                    <CinemaPage />
                                </Route>
                                <Route
                                    path="/movies/:id/:movieTitle?"
                                    exact
                                >
                                    <CinemaMoviePage />
                                </Route>
                                <Route
                                    path="/kino/kinoprogramm/movies/:id/:movieTitle?"
                                    exact
                                >
                                    <CinemaMoviePage />
                                </Route>
                                {/* check later */}
                                <Route
                                    path="/kino/articles/:id/:kinoTitle?"
                                    exact
                                >
                                    <NewsArticle />
                                </Route>
                                <Route
                                    path="/mcdonald-burger-alarm"
                                    exact
                                >
                                    <MacdoBurgerAlarmPage />
                                </Route>
                                <Route
                                    path="/baby-of-the-month"
                                    exact
                                >
                                    <BabyOfTheMonthPage />
                                </Route>
                                <Route
                                    path="/aktuell/news/:tag"
                                    render={() => <NewsPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/:category/a/:id/:articleTitle?"
                                    render={() => <NewsArticle />}
                                    exact
                                />
                                <Route
                                    path="/radio/live-stream/:link?"
                                    component={LiveStreamPage}
                                    exact
                                />
                                <Route
                                    path="/radio/rubriken"
                                    render={() => <TopicsCategoriesPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/rubriken/:slug"
                                    render={() => <ActionsArticlePage />}
                                    exact
                                />
                                <Route
                                    path="/radio/gaming"
                                    render={() => <CategoryPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/travel"
                                    render={() => <CategoryPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/travel/:tag"
                                    render={() => <CategoryListPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/fashionAndBeauty"
                                    render={() => <CategoryPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/fashionAndBeauty/:tag"
                                    render={() => <CategoryListPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/lifestyle"
                                    render={() => <CategoryPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/lifestyle/:tag"
                                    render={() => <CategoryListPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/foodAndDrinks"
                                    render={() => <CategoryPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/foodAndDrinks/:tag"
                                    render={() => <CategoryListPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/shows"
                                    render={() => <ShowsPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/team"
                                    component={TeamPage}
                                />
                                <Route
                                    path="/radio/:category"
                                    render={() => <NewsPage />}
                                    exact
                                />
                                <Route
                                    path="/aktuell/news"
                                    render={() => <NewsPage />}
                                    exact
                                />
                                <Route
                                    path="/aktuell/news/a/:id/:articleTitle?"
                                    render={() => <NewsArticle />}
                                    exact
                                />
                                <Route
                                    path="/radio/shows/all"
                                    render={() => <ShowsList />}
                                    exact
                                />
                                <Route
                                    path="/events/fotoen"
                                    exact
                                    render={() => <PicturesReports />}
                                />
                                <Route
                                    path="/events/fotoen/:id/:pictureReportTitle?"
                                    render={() => <PicturesReport />}
                                />
                                <Route
                                    path="/traffic-info"
                                    component={TrafficInfo}
                                />
                                <Route
                                    path="/game"
                                    component={GamePage}
                                    exact
                                />
                                <Route
                                    path="/game/:id"
                                    render={() => <GameFormPage />}
                                    exact
                                />
                                <Route
                                    path="/events/ticket-bourse"
                                    component={TicketingPage}
                                    exact
                                />
                                <Route
                                    path="/events/ticket-bourse/sendTicket"
                                    component={TicketBourseForm}
                                    exact
                                />
                                <Route
                                    path="/events/ticket-bourse-search"
                                    component={TicketingSearchPage}
                                />
                                <Route
                                    path="/terms"
                                    component={LegalMentions}
                                />
                                <Route
                                    path="/frequencies"
                                    component={FrequenciesPage}
                                />
                                <Route
                                    path="/podcast-replay"
                                    render={() => <PodcastReplay />}
                                    exact
                                />
                                <Route
                                    path="/aktiounen"
                                    render={() => <ActionsPage />}
                                    exact
                                />
                                <Route
                                    path="/aktiounen/:slug"
                                    render={() => <ActionsArticlePage />}
                                    exact
                                />
                                <Route
                                    path="/radio/shows/event-navigator"
                                    render={() => <ActionsArticlePage />}
                                    exact
                                />

                                <Route
                                    path="/podcast-replay/category/:category"
                                    render={() => <PodcastOrReplayCategorized />}
                                    exact
                                />
                                <Route
                                    path="/podcast-replay/type/:type/:id/:podcastTitle?"
                                    render={() => <PodcastReplayOrEldo4Real />}
                                    exact
                                />
                                <Route
                                    path="/events/agenda"
                                    render={() => <EventsPage />}
                                    exact
                                />
                                <Route
                                    path="/radio/shows/event-navigator/archive"
                                    render={() => <EventsArchivePage />}
                                    exact
                                />
                                <Route
                                    path="/events/agenda/:id/:eventTitle?"
                                    render={() => <EventArticle />}
                                />
                                <Route
                                    path="/events-search"
                                    render={() => <EventSearchPage />}
                                    exact
                                />
                                <Route
                                    path="/musek/charts"
                                    component={ChartBreakPage}
                                    exact
                                />
                                <Route
                                    path="/musek/charts/:chart"
                                    render={() => <ChartBreakerTopic />}
                                    exact
                                />
                                <Route
                                    path="/onboarding"
                                    component={OnboardingPage}
                                    exact
                                />
                                <Route
                                    path="/letzAi/:id"
                                    component={LetzAiApprovedPicture}
                                    exact
                                />

                                <Route
                                    path="/"
                                    component={HomePage}
                                    exact
                                />
                                <Route
                                    path="/home/:playground?/:playgroundId?"
                                    component={HomePage}
                                    exact
                                />
                                <Redirect
                                    from="*"
                                    to="/home"
                                />
                            </Switch>
                        </IonRouterOutlet>
                        <IonTabBar
                            slot="bottom"
                            id="tabBtn"
                            className="eldo-bottom-tab-bar"
                        >
                            <IonTabButton
                                tab="eldoradio"
                                href="/home"
                                className="eldo-bottom-tab-bar__btn eldo-bottom-tab-bar__btn-normal"
                            >
                                <AppIcon
                                    name={HOME}
                                    iconType="css"
                                />
                                <IonLabel>Eldo</IonLabel>
                            </IonTabButton>

                            <IonTabButton
                                tab="radio"
                                href="/aktuell/radio"
                                className="eldo-bottom-tab-bar__btn eldo-bottom-tab-bar__btn-normal"
                            >
                                <AppIcon
                                    name={RADIO}
                                    iconType="css"
                                />
                                <IonLabel>Radio</IonLabel>
                            </IonTabButton>

                            <IonTabButton
                                tab="giveaway"
                                href="/game"
                                className="eldo-bottom-tab-bar__btn eldo-bottom-tab-bar__btn-special"
                            >
                                <AppIcon
                                    name="giveaway"
                                    iconType="css"
                                />
                            </IonTabButton>

                            <IonTabButton
                                tab="news"
                                href="/aktuell/news"
                                className="eldo-bottom-tab-bar__btn eldo-bottom-tab-bar__btn-normal"
                            >
                                <AppIcon
                                    name={NEWS}
                                    iconType="css"
                                />
                                <IonLabel>News</IonLabel>
                            </IonTabButton>

                            <IonTabButton
                                tab="menu"
                                href="/menu"
                                className="eldo-bottom-tab-bar__btn eldo-bottom-tab-bar__btn-normal"
                                onClick={(e) => {
                                    // @ts-ignore
                                    if (!e?.detail?.href || !window.location.href || !router.current) return;
                                    // @ts-ignore
                                    const isAlreadyOnTab = window.location.href.includes(e?.detail?.href);
                                    if (isAlreadyOnTab) {
                                        router.current.history.goBack();
                                    }
                                }}
                            >
                                <AppIcon
                                    name={MENU}
                                    iconType="css"
                                />
                                <IonLabel>Menu</IonLabel>
                            </IonTabButton>
                        </IonTabBar>
                    </IonTabs>
                ) : (
                    ''
                )}
            </IonReactRouter>
        </IonApp>
    );
}

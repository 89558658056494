import { IonCard } from '@ionic/react';
import moment from 'moment';
import { Game } from 'types';
import { ReactComponent as Pin } from 'components/Icons/pin.svg';
import { ReactComponent as Calendar2 } from 'components/Icons/calendar2.svg';
import { ReactComponent as Giveaway } from 'components/Icons/giveaway.svg';
import Badge from 'components/Badge';

function GameCard({ name, location, dateTime, badge, className }: Game & { badge: number; className?: string }) {
    const date = moment(dateTime).format('DD.MM.YY');

    return (
        <IonCard className={`h-[180px] m-0 p-0 ${className || ''}`}>
            <div className="py-[20px] px-[18px] flex-1 flex justify-between">
                <div className="text-left">
                    <div className="flex items-center gap-[15px]">
                        <Badge
                            text={badge}
                            className="w-[34px]"
                        >
                            <Giveaway />
                        </Badge>

                        <div className="font-semibold typo-b1 leading-6 line-clamp-2">{name}</div>
                    </div>
                    <div className="typo-b2 mt-[10px] flex gap-[16px] items-center">
                        <Pin className="w-[20px]" />
                        {location}
                    </div>
                    <div className="typo-b2 flex gap-[16px] mt-[10px] items-center">
                        <Calendar2 className="w-[20px]" />
                        {date}
                    </div>
                </div>
            </div>
        </IonCard>
    );
}

export default GameCard;

import { IonCard, IonCardTitle } from '@ionic/react';
import AppIcon from 'components/Icons/AppIcon';

type WidgetProps = {
    icon: 'weather' | 'traffic' | 'gaming' | 'ticket';
    title: string;
    path: string;
    className?: string;
};
const WIDGET_ICONS_PATH = {
    weather: '/assets/img/widgets/weather.webp',
    traffic: '/assets/img/widgets/trafic.webp',
    gaming: '/assets/img/widgets/gaming_widget.webp',
    ticket: '/assets/img/widgets/ticket_bourse_widget.webp',
};
const WIDGET_ICONS_LEFT_PATH = {
    weather: '/assets/img/widgets/meteo_left.svg',
    traffic: '/assets/img/widgets/traffic_left.svg',
    gaming: '/assets/img/widgets/meteo_left.svg',
    ticket: '/assets/img/widgets/traffic_left.svg',
};

function EldoWidget({ icon, title, path, className }: WidgetProps) {
    return (
        <IonCard
            button
            className={`eldo-widget-card cursor-pointer relative h-[125px] p-0 mb-0 mt-0 object-cover ${className || ''}`}
            routerLink={path}
            style={{
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                backgroundImage: `url(${WIDGET_ICONS_PATH[icon]})`,
                backgroundSize: 'cover',
            }}
        >
            <img
                className="absolute left-0 bottom-0"
                src={(WIDGET_ICONS_LEFT_PATH[icon])}
                alt={`${title}`}
            />
            <div className="bg-white w-[200px] flex items-center text-[#0F4368] absolute top-1/2 transform -translate-y-1/2 rounded px-[16px] py-[8px] left-[15%]">
                <AppIcon name={`${icon}-widget`} />
                <IonCardTitle class="typo-b1 semibold">{title}</IonCardTitle>
            </div>
        </IonCard>
    );
}

export default EldoWidget;
